<template>
  <!-- WITH PARTNER -->
  <section class="tab_content withpartner" role="tabpanel">
    <div class="box_title">
      <h2 class="title">WITH PARTNER PROGRAM</h2>
      <p class="description" v-html="strReplace($t('content.programs.with_partner.program_desc'),'<br />')">
      </p>
    </div>

    <div class="box_title">
      <h3 class="sub_title">{{$t('content.programs.with_partner.partners')}}
      </h3>
    </div>
    <StaggerTransition
      id="partners"
    >
      <div class="report_box">
        <ul class="major_list col2 ani-stagger">
          <li class="major_item">
            <img :src="requireAssetsMobileImage('programs/img_partner_hyundai.png')">
          </li>
          <li class="major_item">
            <img :src="requireAssetsMobileImage('programs/img_partner_eland.png')">
          </li>
          <li class="major_item">
            <img :src="requireAssetsMobileImage('programs/img_partner_kyowon.png')">
          </li>
          <li class="major_item">
            <img :src="requireAssetsMobileImage('programs/img_partner_yes24.png')">
          </li>
          <li class="major_item">
            <img :src="requireAssetsMobileImage('programs/img_partner_kum.png')">
          </li>
          <li class="major_item">
            <img :src="requireAssetsMobileImage('programs/img_partner_bayer.png')">
          </li>
          <li class="major_item">
            <img :src="requireAssetsMobileImage('programs/img_partner_dhn.png')">
          </li>
          <li class="major_item">
            <img :src="requireAssetsMobileImage('programs/img_partner_kcca.png')">
          </li>
        </ul>
      </div>
    </StaggerTransition>

    <div class="box_title">
      <h3 class="sub_title">{{$t('content.programs.with_partner.program_benefit')}}
      </h3>
    </div>
    <div class="report_box">
      <ul class="benefit_list">
        <li class="benefit_item" data-aos="fade-up">
          <span class="ico_startup"></span>
          <strong class="name">BRAND SUPPORT</strong>
          <p class="info" v-html="$t('content.programs.with_partner.benefit.support_desc')">
          </p>
        </li>
        <li class="benefit_item" data-aos="fade-up">
          <span class="ico_network"></span>
          <strong class="name">NETWORKING</strong>
          <p class="info" v-html="$t('content.programs.with_partner.benefit.networking_desc')">
          </p>
        </li>
        <li class="benefit_item" data-aos="fade-up">
          <span class="ico_investment"></span>
          <strong class="name">INVESTMENT</strong>
          <p class="info" v-html="$t('content.programs.with_partner.benefit.investment_desc')">
          </p>
        </li>
      </ul>
    </div>
    <div class="box_title">
      <h3 class="sub_title">{{$t('content.programs.with_partner.selection_process')}}
      </h3>
    </div>
    <div class="report_box">
      <ul class="process_list" data-aos="fade-up">
        <li class="process_item">
          <div class="icon ico_document"></div>
          <div class="process">
            <em class="step">STEP01</em>
            <div class="name" v-html="$t('content.programs.with_partner.process.step01')">
            </div>
          </div>
        </li>
        <li class="process_item">
          <div class="icon ico_screening"></div>
          <div class="process">
            <em class="step">STEP02</em>
            <div class="name" v-html="$t('content.programs.with_partner.process.step02')">
            </div>
          </div>
        </li>
        <li class="process_item">
          <div class="icon ico_screening"></div>
          <div class="process">
            <em class="step">STEP03</em>
            <div class="name" v-html="$t('content.programs.with_partner.process.step03')">
            </div>
          </div>
        </li>
        <li class="process_item">
          <div class="icon ico_successful"></div>
          <div class="process">
            <em class="step">STEP04</em>
            <div class="name" v-html="$t('content.programs.with_partner.process.step04')">
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="box_title">
      <h3 class="sub_title">{{$t('content.programs.with_partner.participation_example')}}
      </h3>
    </div>
    <div class="report_box">
      <div class="program_join">
        <!-- 프로그램 참여 사례 1 : 웰트 -->
        <div class="join_case" data-aos="fade-up">
          <div class="thumb">
            <img :src="requireAssetsMobileImage('programs/img_program_case3.jpg')">
          </div>
          <div class="interview">
            <strong class="company">{{$t('content.programs.with_partner.example.welt.company')}}
            </strong>
            <em class="collabo">{{$t('content.programs.with_partner.example.welt.participant')}}
            </em>
            <p class="review"
               v-html="strReplace($t('content.programs.with_partner.example.welt.description'),'<br />')">
            </p>
            <div class="name">{{$t('content.programs.with_partner.example.welt.ceo')}}
            </div>
          </div>
        </div>
        <!-- 프로그램 참여 사례 2 : 코리아스타트업포럼 -->
        <div class="join_case" data-aos="fade-up">
          <div class="thumb">
            <img :src="requireAssetsMobileImage('programs/img_program_case4.jpg')">
          </div>
          <div class="interview">
            <strong
              class="company">{{$t('content.programs.with_partner.example.korea_startup_forum.company')}}
            </strong>
            <em
              class="collabo">{{$t('content.programs.with_partner.example.korea_startup_forum.participant')}}
            </em>
            <p class="review"
              v-html="strReplace($t('content.programs.with_partner.example.korea_startup_forum.description'),'<br />')">
            </p>
            <div class="name">
              {{$t('content.programs.with_partner.example.korea_startup_forum.ceo')}}
            </div>
          </div>
        </div>
        <!-- 프로그램 참여 사례 3 : 로켓뷰 -->
        <div class="join_case" data-aos="fade-up">
          <div class="thumb">
            <img :src="requireAssetsMobileImage('programs/img_program_case5.jpg')">
          </div>
          <div class="interview">
            <strong
              class="company">{{$t('content.programs.with_partner.example.rocketview.company')}}
            </strong>
            <em
              class="collabo">{{$t('content.programs.with_partner.example.rocketview.participant')}}
            </em>
            <p class="review"
              v-html="strReplace($t('content.programs.with_partner.example.rocketview.description'),'<br />')">
            </p>
            <div class="name">{{$t('content.programs.with_partner.example.rocketview.ceo')}}</div>
          </div>
        </div>
        <!-- 프로그램 참여 사례 4 : 디자이노블 -->
        <div class="join_case" data-aos="fade-up">
          <div class="thumb">
            <img :src="requireAssetsMobileImage('programs/img_program_case6.jpg')">
          </div>
          <div class="interview">
            <strong
              class="company">{{$t('content.programs.with_partner.example.designovle.company')}}
            </strong>
            <em
              class="collabo">{{$t('content.programs.with_partner.example.designovle.participant')}}
            </em>
            <p class="review"
              v-html="strReplace($t('content.programs.with_partner.example.designovle.description'),'<br />')">
            </p>
            <div class="name">{{$t('content.programs.with_partner.example.designovle.ceo')}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="box_title">
      <h3 class="sub_title">ALUMNI</h3>
    </div>
    <div class="alumni_area">
      <div class="alumni_swiper">
        <MobileBaseTab
          v-model="tabValue"
          v-bind:list="titleList"
          @input="tabClick">
        </MobileBaseTab>
        <div class="partner_swiper scroll_slider">
          <ScrolledSlider
            :location="location"
            :list="alumniList[tabValue]"
            :itemWidth="100"
            :itemsPerRows="4">
            <!--
            <template v-slot="{ item }">
              <div class="slider"
                v-for="(subItem, subIndex) in item.subList" :key="subIndex">
                <div class="card-wrap">
                  <div class="card">
                    <img v-if="subItem.image" :src="requireAssetsImg(subItem.image)"
                      :alt="subItem.name">
                  </div>
                </div>
              </div>
            </template>
            -->
          <template v-slot="{ item }">
                <div class="slider" :class="{ 'hide': item.img ===''} ">
                  <div class="card-wrap">
                    <div class="card">
                      <img v-if="item.img" :src="requireAssetsImg(`${item.img}`)"
                            :alt="item.title">
                      <span :class="item.img ? 'blind': 'card-logo-text'">{{item.title}}</span>
                    </div>
                  </div>
                </div>
              </template>
          </ScrolledSlider>
        </div>
      </div>
    </div>
  </section>
  <!-- // WITH PARTNER -->
</template>

<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import StaggerTransition from '@/components/transition/StaggerTransition.vue';
import ScrolledSlider from '@/components/slider/ScrolledSlider.vue';
import MobileBaseTab from '@/components/base/MobileBaseTab.vue';
import _ from 'lodash';

export default {
  name: 'NewWithPartner',
  mixins: [
    mixinHelperUtils,
  ],
  components: {
    StaggerTransition,
    ScrolledSlider,
    MobileBaseTab,
  },
  data() {
    return {
      /**
       * ALUMNI 리스트
       * @type {array}
       */
      alumniList: [
        // with ELAND
        [
            { title: 'Linder(히든트랙)', img: 'programs/logo_eland_member1.png' },
            { title: '디자이노블', img: 'programs/logo_eland_member2.png' },
            { title: '디파인드', img: 'programs/logo_eland_member3.png' },
            { title: '로켓뷰', img: 'programs/logo_eland_member4.png' },
            { title: '메이아이', img: 'programs/logo_eland_member5.png' },
            { title: '몬데이터', img: 'programs/logo_eland_member6.png' },
            { title: '소프트런치', img: 'programs/logo_eland_member7.png' },
            { title: '아자스쿨', img: 'programs/logo_eland_member8.png' },
            { title: '와이드필드', img: 'programs/logo_eland_member9.png' },
            { title: '인포그린', img: 'programs/logo_eland_member10.png' },
            { title: '클린그린', img: 'programs/logo_eland_member11.png' },
            { title: '태그바이', img: 'programs/logo_eland_member12.png' },
            { title: '땡큐마켓', img: 'programs/logo_eland_member13.png' },
            { title: '코너마켓', img: 'programs/logo_eland_member14.png' },
            { title: 'PLZ', img: 'programs/logo_eland_member15.png' },
          ],
        // with KOCCA
        [
            { title: 'NEOCOMIX', img: 'programs/logo_kocca_member1.png' },
            { title: '둥글', img: 'programs/logo_kocca_member2.png' },
            { title: '보이스루', img: 'programs/logo_kocca_member3.png' },
            { title: '브랜뉴테크(비비빅)', img: 'programs/logo_kocca_member4.png' },
            { title: '브레인콜라', img: 'programs/logo_kocca_member5.png' },
            { title: '엑씽크', img: 'programs/logo_kocca_member6.png' },
            { title: '엠랩', img: 'programs/logo_kocca_member7.png' },
            { title: '클린그린', img: 'programs/logo_kocca_member8.png' },
            { title: '마블러스', img: 'programs/logo_kocca_member9.png' },
            { title: '두리번', img: 'programs/logo_kocca_member10.png' },
            { title: '애드히어로', img: 'programs/logo_kocca_member11.png' },
        ],
        // with LOTTE HOMESHOPPING
        [
            { title: '아키드로우', img: 'programs/logo_lotte_member1.png' },
            { title: '엠랩', img: 'programs/logo_lotte_member2.png' },
        ],
        // with ZERO1NE
        [
            { title: 'EYERIS', img: 'programs/logo_zero1ne_member1.png' },
            { title: '마인드아이', img: 'programs/logo_zero1ne_member2.png' },
            { title: '몬가타', img: 'programs/logo_zero1ne_member3.png' },
            { title: '비트센싱', img: 'programs/logo_zero1ne_member4.png' },
            { title: '솔티드', img: 'programs/logo_zero1ne_member5.png' },
            { title: '애니랙티브', img: 'programs/logo_zero1ne_member6.png' },
            { title: '에이아이트릭스', img: 'programs/logo_zero1ne_member7.png' },
            { title: '피트메디', img: 'programs/logo_zero1ne_member8.png' },
        ],
        // with 라이프스타일 1기
        [
            { title: '두잉랩', img: 'programs/logo_ls1_member1.png' },
            { title: '마보', img: 'programs/logo_ls1_member2.png' },
            { title: '마일로', img: 'programs/logo_ls1_member3.png' },
            { title: '삼쩜일사', img: 'programs/logo_ls1_member4.png' },
            { title: '아토머스', img: 'programs/logo_ls1_member5.png' },
            { title: '키튼플래닛', img: 'programs/logo_ls1_member6.png' },
            { title: '티스쿨컴퍼니', img: 'programs/logo_ls1_member7.png' },
        ],
        // 라이프스타일 2기
        [
            { title: 'Trost(휴마트컴퍼니)', img: 'programs/logo_ls2_member1.png' },
            { title: '딥메디', img: 'programs/logo_ls2_member2.png' },
            { title: '라이앤캐처스', img: 'programs/logo_ls2_member3.png' },
            { title: '룬랩', img: 'programs/logo_ls2_member4.png' },
            { title: '뤼이드', img: 'programs/logo_ls2_member5.png' },
            { title: '머니매그넷', img: 'programs/logo_ls2_member6.png' },
            { title: '브레인콜라', img: 'programs/logo_ls2_member7.png' },
            { title: '서틴스플로어', img: 'programs/logo_ls2_member8.png' },
            { title: '아자스쿨', img: 'programs/logo_ls2_member9.png' },
            { title: '엑씽크', img: 'programs/logo_ls2_member10.png' },
            { title: '원드롭', img: 'programs/logo_ls2_member11.png' },
            { title: '웨인힐스벤처스 ', img: 'programs/logo_ls2_member12.png' },
            { title: '웰트 ', img: 'programs/logo_ls2_member13.png' },
            { title: '유커넥 ', img: 'programs/logo_ls2_member14.png' },
            { title: '임퍼펙트 ', img: 'programs/logo_ls2_member15.png' },
            { title: '펫나우 ', img: 'programs/logo_ls2_member16.png' },
            { title: '펫트너 ', img: 'programs/logo_ls2_member17.png' },
            { title: '핑거앤 ', img: 'programs/logo_ls2_member18.png' },
            { title: '더매치랩 ', img: 'programs/logo_ls2_member19.png' },
        ],
      ],
      titleList: ['with ELAND',
                'with KOCCA',
                'with LOTTE HOMESHOPPING',
                'with ZERO1NE',
                this.$t('content.programs.with_partner.alumni.lifestyle_01'),
                this.$t('content.programs.with_partner.alumni.lifestyle_02'),
                ],
      /**
       * 현재 ALUMNI 탭
       * @type {number}
       */
      location: 0,
      tabValue: 0,
    };
  },
  computed: {
    /**
     * ALUMNI 탭 리스트
     * @type {string[]}
     */
    alumniTabList() {
      return this.alumniList.map((item) => item.name);
    },
    /**
     * 분할된 ALUMNI 리스트 (18개 단위로 분할)
     * @type {array}
     */
    chunkedAlumniList() {
      return this.alumniList.reduce((list, item, index) => list.concat(
        _.chunk(item.subList)
          .map((chunkedSubList) => ({
            name: item.name,
            subList: chunkedSubList,
            tabIndex: index,
          })),
      ), []);
      // this.alumniList[this.currentAlumniTab].subList;
    },
  },

  methods: {
    /**
     * ALUMNI 탭 선택 이벤트 처리
     * @param {number} tabIndex
     */
    onAlumniTabChange(tabIndex) {
      if (tabIndex >= 0) {
        const slideIndex = this.chunkedAlumniList
          .findIndex((item) => item.tabIndex === tabIndex);
        this.location = slideIndex;
      }
    },
      tabClick(i) {
      this.tabValue = i;
  },
    /**
     * ALUMNI 슬라이더 변경 이벤트 처리
     * @param {number} slideIndex
     */
    // onAlumniSliderChange(slideIndex) {
    //   const { tabIndex } = this.chunkedAlumniList[slideIndex];
    //   this.currentAlumniTab = tabIndex;
    // },
  },
};
</script>
