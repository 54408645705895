<template>
  <article class="programs_cont">
    <section class="visual_intro">
      <div class="visual_wall"></div>
      <div class="intro_title">
        <strong class="title">BUSINESS ENABLER</strong>
        <strong class="title">DREAMPLUS</strong>
      </div>
    </section>
    <section class="report_group">
      <MobileBaseTab
        v-model="tabValue"
        :list="reportTabList.name"
        tabtype="line"
        @input="reportTabClick">
        <!-- <template v-slot="Scope">
          <a class="tab" role="tab" :class="Scope.paramClass">{{Scope.title}}</a>
        </template> -->
      </MobileBaseTab>
      <!-- <div class="tab_list line" role="tablist">
        <div class="tab_wrap" role="presentation">
          <a href="javascript:void(0);"
             v-for="(tab, index) in reportTabList" :key="index"
             class="tab"
             role="tab"
             :aria-selected="tab.component === tabComponent ? 'true' : ''"
             @click="reportTabClick(tab.component)"
          >{{tab.name}}</a>
        </div>
      </div> -->
      <component :is="reportTabClick(tabValue)"></component>
    </section>
  </article>
</template>
<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import MobileBaseTab from '@/components/base/MobileBaseTab.vue';
import NewWithHanwha from '@/views/programs/MobileNewWithHanwha.vue';
import NewWithPartner from '@/views/programs/MobileNewWithPartner.vue';
import Internship from '@/views/programs/MobileInternship.vue';

export default {
  name: 'MobileNewPrograms',
  mixins: [
    mixinHelperUtils,
  ],
  components: {
    MobileBaseTab,
    NewWithHanwha,
    NewWithPartner,
    Internship,
  },
  data() {
    return {
      tabValue: 0,
      tabComponent: 'NewWithHanwha',
      reportTabList: {
        name: ['WITH HANWHA', 'WITH PARTNER', 'INTERNSHIP'],
        component: ['NewWithHanwha', 'NewWithPartner', 'Internship'],
      },
    };
  },
  methods: {
    reportTabClick(tab) {
      this.tabComponent = this.reportTabList.component[tab];
      return this.tabComponent;
    },
  },
  mounted() {
    const elVisual = document.getElementsByClassName('visual_intro');
    setTimeout(() => {
      elVisual[0].className += ' ani_intro';
    }, 100);
  },
};
</script>
