<template>
  <!-- INTERNSHIP -->
  <section class="tab_content internship" role="tabpanel">
    <div class="report_box">
      <div class="box_title">
        <h2 class="title">INTERNSHIP</h2>
        <p class="description" v-html="strReplace($t('content.programs.internship.internship_desc'),'<br />')">
        </p>
      </div>
    </div>
    <div class="report_box bg_gray">
      <div class="box_title">
        <h3 class="sub_title">{{$t('content.programs.internship.youth_tlo')}}
        </h3>
      </div>
      <div class="box_cont">
        <p class="program_desc" v-html="strReplace($t('content.programs.internship.youth_tlo_desc'),'<br />')">
        </p>
        <StaggerTransition
          id="long-term-internship"
        >
          <div>
            <ul class="major_list col2">
              <li class="major_item">
                <img :src="requireAssetsMobileImage('programs/logo_tlo_intern_compa.png')">
              </li>
              <li class="major_item">
                <img :src="requireAssetsMobileImage('programs/logo_tlo_intern_hyu.png')">
              </li>
              <li class="major_item">
                <img :src="requireAssetsMobileImage('programs/logo_tlo_intern_ysu.png')">
              </li>
              <li class="major_item">
                <img :src="requireAssetsMobileImage('programs/logo_tlo_intern_ku.png')">
              </li>
              <li class="major_item">
                <img :src="requireAssetsMobileImage('programs/logo_tlo_intern_ewha.png')">
              </li>
              <li class="major_item">
                <img :src="requireAssetsMobileImage('programs/logo_tlo_intern_snu.png')">
              </li>
              <li class="major_item">
                <img :src="requireAssetsMobileImage('programs/logo_tlo_intern_duksung.png')">
              </li>
              <li class="major_item">
                <img :src="requireAssetsMobileImage('programs/logo_tlo_intern_chungnam.png')">
              </li>
            </ul>
          </div>
        </StaggerTransition>
      </div>
    </div>
    <div class="report_box">
      <div class="box_title">
        <h3 class="sub_title">{{$t('content.programs.internship.college_internship')}}
        </h3>
      </div>
      <StaggerTransition
        id="short-term-internship"
      >
        <ul class="university_list">
          <li class="university_item">
            <img :src="requireAssetsMobileImage('programs/logo_university_yonsei.png')" class="logo">
            <strong class="name">{{$t('content.programs.internship.colleges.yonsei')}}
            </strong>
            <p class="desc">{{$t('content.programs.internship.colleges.yonsei_desc')}}
            </p>
          </li>
          <li class="university_item">
            <img :src="requireAssetsMobileImage('programs/logo_university_ehwa.png')" class="logo">
            <strong class="name">{{$t('content.programs.internship.colleges.ewha')}}
            </strong>
            <p class="desc">{{$t('content.programs.internship.colleges.ewha_desc')}}
            </p>
          </li>
          <li class="university_item">
            <img :src="requireAssetsMobileImage('programs/logo_university_hufs.png')" class="logo">
            <strong class="name">{{$t('content.programs.internship.colleges.hufs')}}
            </strong>
            <p class="desc">{{$t('content.programs.internship.colleges.hufs_desc')}}
            </p>
          </li>
        </ul>
      </StaggerTransition>
    </div>
    <div class="report_box">
      <div class="box_title">
        <h3 class="sub_title">{{$t('content.programs.internship.selection_process')}}
        </h3>
      </div>
      <div>
        <ul class="process_list" data-aos="fade-up">
          <li class="process_item">
            <div class="icon ico_document"></div>
            <div class="process">
              <em class="step">STEP01</em>
              <div class="name" v-html="$t('content.programs.internship.process.step01')">
              </div>
            </div>
          </li>
          <li class="process_item">
            <div class="icon ico_screening"></div>
            <div class="process">
              <em class="step">STEP02</em>
              <div class="name" v-html="$t('content.programs.internship.process.step02')">
              </div>
            </div>
          </li>
          <li class="process_item">
            <div class="icon ico_interview"></div>
            <div class="process">
              <em class="step">STEP03</em>
              <div class="name" v-html="$t('content.programs.internship.process.step03')">
              </div>
            </div>
          </li>
          <li class="process_item">
            <div class="icon ico_matching"></div>
            <div class="process">
              <em class="step">STEP04</em>
              <div class="name" v-html="$t('content.programs.internship.process.step04')">
              </div>
            </div>
          </li>
          <li class="process_item">
            <div class="icon ico_successful"></div>
            <div class="process">
              <em class="step">STEP05</em>
              <div class="name" v-html="$t('content.programs.internship.process.step05')">
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="report_box">
      <div class="box_title">
        <h3 class="sub_title">ACADEMY</h3>
        <p class="program_desc" v-html="strReplace($t('content.programs.internship.academy_desc'),'<br />')">
        </p>
      </div>
      <StaggerTransition
        id="academy-benefits"
      >
        <div>
          <ul class="benefit_list academy">
            <li class="benefit_item">
              <div class="academy">
                <div class="icon ico_blockchain"></div>
                <strong class="name">BLOCKCHAIN ACADEMY</strong>
                <em class="with">with UC Berkeley</em>
                <p class="desc" v-html="$t('content.programs.internship.academy.blockchain_desc')">
                </p>
              </div>
            </li>
            <li class="benefit_item">
              <div class="academy">
                <div class="icon ico_fashion"></div>
                <strong class="name">FASHION &amp; GOODS ACADEMY</strong>
                <em class="with">with GS SHOP</em>
                <p class="desc" v-html="$t('content.programs.internship.academy.fashion_goods_desc')">
                </p>
              </div>
            </li>
            <li class="benefit_item">
              <div class="academy">
                <div class="icon ico_kitchen"></div>
                <strong class="name">F&amp;B ACADEMY</strong>
                <em class="with">{{$t('content.programs.internship.academy.fNb_with')}}
                </em>
                <p class="desc" v-html="$t('content.programs.internship.academy.fNb_desc')">
                </p>
              </div>
            </li>
            <li class="benefit_item">
              <div class="academy">
                <div class="icon ico_academy"></div>
                <strong class="name">ONBOARDING STARTUP ACADEMY</strong>
                <em class="with">with Google</em>
                <p class="desc" v-html="$t('content.programs.internship.academy.onboarding_desc')">
                </p>
              </div>
            </li>
            <li class="benefit_item">
              <div class="academy">
                <div class="icon ico_creator"></div>
                <strong class="name">CONTENTS CREATOR</strong>
                <em class="with">{{$t('content.programs.internship.academy.contents_with')}}
                </em>
                <p class="desc" v-html="$t('content.programs.internship.academy.contents_desc')">
                </p>
              </div>
            </li>
            <li class="benefit_item">
              <div class="academy">
                <div class="icon ico_developer"></div>
                <strong class="name">DREAMIN iOS DEVELOPER<br>ACADEMY</strong>
                <p class="desc" v-html="$t('content.programs.internship.academy.ios_desc')">
                </p>
              </div>
            </li>
          </ul>
        </div>
      </StaggerTransition>
    </div>
    <div class="report_box">
      <div class="box_title">
        <h3 class="sub_title">{{$t('content.programs.internship.academy_image')}}
        </h3>
      </div>
      <div class="academy_swiper slider_type1">
        <!-- 이미지 슬라이더 -->
        <MobileBaseSlider
          ref="academySlider"
          :autoplay="false"
          :list="academySliderList"
          :pagination="true"
          :navigation="false"
        >
          <template v-slot="{ item }">
            <div class="academy_place">
              <img :src="item.img" :alt="`${item.title} 이미지`" />
            </div>
          </template>
        </MobileBaseSlider>
      </div>
    </div>
  </section>
  <!-- // INTERNSHIP -->
</template>
<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import MobileBaseSlider from '@/components/slider/MobileBaseSlider.vue';
import StaggerTransition from '@/components/transition/StaggerTransition.vue';

export default {
  name: 'Internship',
  mixins: [
    mixinHelperUtils,
  ],
  components: {
    MobileBaseSlider,
    StaggerTransition,
  },
  data() {
    return {
      academySliderList: [
        {
          img: this.requireAssetsMobileImage('programs/img_academy_place01.jpg'),
          title: 'academy_place01',
        },
        {
          img: this.requireAssetsMobileImage('programs/img_academy_place02.jpg'),
          title: 'academy_place02',
        },
        {
          img: this.requireAssetsMobileImage('programs/img_academy_place03.jpg'),
          title: 'academy_place03',
        },
        {
          img: this.requireAssetsMobileImage('programs/img_academy_place04.jpg'),
          title: 'academy_place04',
        },
        {
          img: this.requireAssetsMobileImage('programs/img_academy_place05.jpg'),
          title: 'academy_place05',
        },
        {
          img: this.requireAssetsMobileImage('programs/img_academy_place06.jpg'),
          title: 'academy_place06',
        },
        {
          img: this.requireAssetsMobileImage('programs/img_academy_place07.jpg'),
          title: 'academy_place07',
        },
      ],
    };
  },
};
</script>
