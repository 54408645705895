<template>
  <Swiper
    :options="options"
    @slideChange="onChange"
    class="myswiper"
  >
    <SwiperSlide
      v-for="(item, index) in list"
      :key="index"
    >
      <!--
        @slot 슬라이드(데이터) 1개에 대한 UI
          @binding item 데이터
      -->
      <slot name="default" :item="item"></slot>
    </SwiperSlide>
    <template v-slot:pagination>
      <div class="swiper-pagination"></div>
    </template>
    <template v-if="navigation" v-slot:button-next>
      <div class="swiper-button-prev"></div>
    </template>
    <template v-if="navigation" v-slot:button-prev>
      <div class="swiper-button-next"></div>
    </template>
  </Swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

/**
 * 기본 슬라이더 컴포넌트
 * @see https://github.com/surmon-china/vue-awesome-swiper
 * @see https://swiperjs.com/
 */
export default {
  name: 'BaseSlider',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    /**
     * 슬라이드 대상 데이터 리스트
     */
    list: {
      type: Array,
    },
    /**
     * 자동 슬라이드 여부
     */
    autoplay: {
      type: Boolean,
      default: true,
    },
    /**
     * 순환 여부
     */
    loop: {
      type: Boolean,
      default: true,
    },
    /**
     * 네비게이션 바
     */
    navigation: {
      type: Boolean,
      default: true,
    },
    /**
     * 페이지 바
     */
    pagination: {
      type: Boolean,
      default: false,
    },
    /**
     * 전체 이미지 사전 로딩 여부
     */
    preloadImages: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    let pagiOptions = {
      el: '.swiper-pagination',
      type: 'bullets',
    };
    pagiOptions = this.pagination
      ? Object.assign(pagiOptions, this.pagination)
      : false;
    return {
      /**
       * @see https://swiperjs.com/api/#parameters
       */
      options: {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: this.loop,
        /**
         * @see https://swiperjs.com/api/#autoplay
         */
        autoplay: this.autoplay
          ? {
            disableOnInteraction: false,
          }
          : false,
        /**
         * @see https://swiperjs.com/api/#pagination
         */
        pagination: pagiOptions,
        /**
         * @see https://swiperjs.com/api/#navigation
         */
        navigation: this.navigation
          ? {
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
          }
          : false, // navigation: false

        preloadImages: this.preloadImages,
      },
    };
  },
  methods: {
    /**
     * 슬라이드 변경시 이벤트 처리
     * @type {number} index
     */
    onChange() {
      /**
       * 변경 이벤트
       * @event change
       * @property {number} 현재 슬라이드 index
       */
      this.$emit('change', this.$el.swiper.realIndex);
    },

    /**
     * 슬라이드 이동
     * @type {number} index
     */
    slideTo(index) {
      this.$el.swiper.slideTo(index + 1);
    },

  },
};
</script>

<style scoped>
@import '~swiper/css/swiper.css';
</style>
<style>
.myswiper .swiper-container-horizontal > .swiper-pagination-bullets { bottom: auto;}
.myswiper .swiper-pagination { position: relative; margin-top: 1.6rem;}
.myswiper .swiper-pagination-bullet{ background-color: #e0e0e0; opacity: 1;}
.myswiper .swiper-pagination-bullet-active { background-color: #000;}
.myswiper .swiper-slide .img_fit{ width: auto; max-width: inherit; height: inherit; object-fit: cover;}
</style>
<!-- https://vue-styleguidist.github.io/docs/Documenting.html -->
<docs>
기본 사용 예시 (외부 이미지) :
```jsx
<BaseSlider
  :list="[
    'https://www.dreamplus.asia/static/favicon/android-icon-72x72.png',
    'https://www.dreamplus.asia/static/favicon/android-icon-96x96.png',
    'https://www.dreamplus.asia/static/favicon/android-icon-144x144.png',
    'https://www.dreamplus.asia/static/favicon/android-icon-192x192.png',
  ]">
  <template v-slot="{ item }">
    <img :src="item" />
  </template>
</BaseSlider>
```

기본 사용 예시 (내부 이미지) :
```jsx
<BaseSlider
  :list="[
    'common/logo.png',
    'dreamplus/logo-motion.png',
  ]">
  <template v-slot="{ item }">
    <img :src="requireAssetsImage(item)" />
  </template>
</BaseSlider>
```
</docs>
